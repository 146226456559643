<template>
  <!-- detail delivery orders -->
  <div class="w-full">
    <div class="flex flex-col w-full items-center space-y-1">
      <div class="flex flex-col w-full items-center space-y-2">
        <div class="flex flex-row w-1/2">
          <div class="w-1/2">
            <span class="text-lg font-bold">Delivery Dates:</span>
          </div>
          <div class="w-full">
            <vs-input
              v-model="deliveryDate"
              type="date"
              class="w-1/2"
              :min="minDate()"
              @input="getVehicle"
              name="delivery_date"
              v-validate="'required'"
            />
            <span v-show="errors.has('delivery_date')" class="text-danger"
              >Delivery Date is required</span
            >
          </div>
        </div>
        <div class="flex flex-row w-1/2">
          <div class="w-1/2">
            <span class="text-lg font-bold">Ownership :</span>
          </div>
          <div class="w-full">
            <multiselect
              class="selectExample"
              v-model="selectedOwnership"
              :options="optionalOwnership"
              :multiple="false"
              :allow-empty="true"
              :group-select="false"
              :max-height="100"
              :limit="3"
              track-by="id"
              placeholder="Type to search"
              :searchable="true"
              label="name"
              :disabled="deliveryDate == ''"
              @select="getVehicle"
            />
          </div>
        </div>
        <div class="flex flex-row w-1/2">
          <div class="w-1/2">
            <span class="text-lg font-bold">Vehicle Number :</span>
          </div>
          <div class="w-full">
            <multiselect
              class="selectExample"
              v-model="selectedVehicle"
              :options="optionalVehicle"
              :multiple="false"
              :allow-empty="true"
              :group-select="false"
              :max-height="100"
              :limit="3"
              track-by="id"
              placeholder="Type to search"
              :searchable="true"
              label="number"
              name="vehicle"
              @search-change="handleSearchVehicle"
              @select="getValueOwnership"
              v-validate="'required'"
            />
            <span v-show="errors.has('vehicle')" class="text-danger"
              >Vehicle is required</span
            >
          </div>
        </div>
        <div class="flex flex-row w-1/2">
          <div class="w-1/2">
            <span class="text-lg font-bold">Vendor :</span>
          </div>

          <div class="w-full">
            <vs-input
              v-model="ownership"
              class="w-full"
              readonly
              :disabled="true"
            />
          </div>
        </div>
        <div class="flex flex-row w-1/2">
          <div class="w-1/2">
            <span class="text-lg font-bold">Type Driver :</span>
          </div>

          <div class="w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTypeDriver"
              :options="optionalTypeDriver"
              :multiple="false"
              :allow-empty="true"
              :group-select="false"
              :max-height="100"
              :limit="3"
              track-by="id"
              placeholder="Type to search"
              :searchable="true"
              label="name"
              name="type_driver"
              @select="getDriver"
              v-validate="'required'"
            />
            <span v-show="errors.has('type_driver')" class="text-danger"
              >Type Driver is required</span
            >
          </div>
        </div>
        <div class="flex flex-row w-1/2">
          <div class="w-1/2">
            <span class="text-lg font-bold">Driver Name :</span>
          </div>

          <div class="w-full">
            <multiselect
              class="selectExample"
              v-model="selectedDriver"
              :options="optionalDriver"
              :multiple="false"
              :allow-empty="true"
              :group-select="false"
              :max-height="100"
              :limit="3"
              track-by="id"
              placeholder="Type to search"
              :searchable="true"
              label="name"
              name="driver"
              @search-change="handleSearchDriver"
              @select="getDataDriver"
              v-validate="'required'"
            />
            <span v-show="errors.has('driver')" class="text-danger"
              >Driver is required</span
            >
          </div>
        </div>
        <div v-if="isOnetime" class="flex flex-row w-1/2">
          <div class="w-1/2">
            <span class="text-lg font-bold">Note :</span>
          </div>
          <div class="w-full">
            <vs-input
              v-model="driverOnetimeNote"
              class="w-full"
              placeholder="Name-NIK"
              v-validate="'required'"
              name="driver_onetime_note"
            />
            <span v-show="errors.has('driver_onetime_note')" class="text-danger"
              >Note is required</span
            >
          </div>
        </div>
      </div>
      <div class="flex flex-row w-1/2 justify-end items-center space-x-1">
        <div v-if="isShowAddVehicle" class="w-1/3"></div>
        <vs-button
          v-if="!isShowAddVehicle"
          @click.stop="showAddVehicle"
          size="small"
          color="success"
          icon-pack="feather"
          icon="icon-plus"
          title="Add Row"
          class="w-1/3"
          >Add Vehicle</vs-button
        >
        <vs-button
          v-on:click="Save()"
          size="small"
          color="primary"
          icon-pack="feather"
          icon="icon-truck"
          title="Save"
          class="w-1/3"
          >Release</vs-button
        >
      </div>
    </div>
    <br />
    <hr />
    <br />
    <div v-if="isShowAddVehicle">
      <div>
        <h2 class="text-lg font-bold">Add Vehicle</h2>
      </div>
      <table
        width="100%"
        class="vs-table vs-table--tbody-table bg-transparent items-center"
      >
        <thead class="vs-table--thead">
          <tr>
            <th width="25%">Ownership</th>
            <th width="15%">Vehicle</th>
            <th width="15%">Vendor</th>
            <th width="10%">Type Driver</th>
            <th width="25%">Driver</th>
            <th width="25%">Note</th>
            <th width="20%"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(child, index) in itemVehicleAdd"
            :key="index"
            style="padding-bottom: 5px"
          >
            <td class="td vs-table--td" style="padding: 5px">
              <multiselect
                class="selectExample"
                v-model="itemVehicleAdd[index].selectedOwnership"
                :options="optionalOwnership"
                :multiple="false"
                :allow-empty="true"
                :group-select="false"
                :max-height="100"
                :limit="3"
                track-by="id"
                select-label=""
                placeholder="Type to search"
                :searchable="true"
                label="name"
                @select="getVehicleAdd(index)"
              />
            </td>
            <td class="td vs-table--td" style="padding: 5px">
              <multiselect
                class="selectExample"
                v-model="itemVehicleAdd[index].Svehicle"
                :options="optionVehicleAdd"
                :multiple="false"
                :allow-empty="true"
                :group-select="false"
                :max-height="100"
                :limit="3"
                track-by="id"
                select-label=""
                placeholder="Type to search"
                :searchable="true"
                label="number"
                name="vehicle_name_child"
                @search-change="handleSearchVehicleAdd($event, index)"
                @select="getValueOwnershipVehicleAdd(index)"
                v-validate="'required'"
              />
              <span
                v-show="errors.has('vehicle_name_child')"
                class="text-danger"
                >Vehicle is required</span
              >
            </td>
            <td class="td vs-table--td" style="padding: 5px">
              <vs-input
                v-model="itemVehicleAdd[index].ownership"
                class="w-full"
                readonly
                :disabled="true"
              />
            </td>
            <td class="td vs-table--td" style="padding: 5px">
              <multiselect
                class="selectExample"
                v-model="itemVehicleAdd[index].selectedTypeDriver"
                :options="optionalTypeDriver"
                :multiple="false"
                :allow-empty="true"
                :group-select="false"
                :max-height="150"
                :limit="3"
                select-label=""
                track-by="id"
                placeholder=""
                :searchable="true"
                label="name"
                name="type_driver"
                @select="getDriverAdd(index)"
              />
            </td>
            <td class="td vs-table--td" style="padding: 5px">
              <multiselect
                class="selectExample"
                v-model="itemVehicleAdd[index].selectedDriver"
                :options="itemVehicleAdd[index].optionalDriver"
                :multiple="false"
                :allow-empty="true"
                :group-select="false"
                :max-height="100"
                :limit="3"
                track-by="id"
                placeholder="Type to search"
                :searchable="true"
                label="name"
                name="driver_name_child"
                v-validate="'required'"
                @search-change="handleSearchDriverAdd($event, index)"
                @select="getDataDriver"
              />
              <span v-show="errors.has('driver_name_child')" class="text-danger"
                >Driver is required</span
              >
            </td>
            <td class="td vs-table--td" style="padding: 5px">
              <vs-input
                v-if="itemVehicleAdd[index].isOnetimes"
                v-model="itemVehicleAdd[index].driverOnetimeNote"
                class="w-full"
                placeholder="Name-NIK"
              />
            </td>
            <td width="75px" class="td vs-table--td" style="text-align: center">
              <div class="vx-input-group flex">
                <vs-button
                  @click.stop="removeRowVehicleSelected(index)"
                  size="small"
                  color="danger"
                  icon-pack="feather"
                  icon="icon-trash"
                  style="margin-right: 5px"
                  title="Remove Row"
                />

                <vs-button
                  @click.stop="addRowVehicleSelected(index)"
                  size="small"
                  color="success"
                  icon-pack="feather"
                  icon="icon-plus"
                  title="Add Row"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <br />
    <hr />
    <br />
    <div>
      <div class="flex flex-row gap-x-3">
        <div class="flex flex-col w-1/2 gap-y-3">
          <div class="flex flex-row">
            <div class="w-1/2">
              <span class="text-lg font-bold">Total Vehicle Weight :</span>
            </div>
            <div class="w-1/2">
              <vs-input
                v-model="TotalVehicle.Weight"
                class="w-full"
                readonly
                :disabled="true"
              />
            </div>
          </div>
          <div class="flex flex-row">
            <div class="w-1/2">
              <span class="text-lg font-bold">Total Vehicle Volume :</span>
            </div>
            <div class="w-1/2">
              <vs-input
                v-model="TotalVehicle.Volume"
                class="w-full"
                readonly
                :disabled="true"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col w-1/2 gap-y-3">
          <div class="flex flex-row">
            <div class="w-1/2">
              <span class="text-lg font-bold">Total DO Weight :</span>
            </div>
            <div class="w-1/2">
              <vs-input
                v-model="TotalDO.Weight"
                class="w-full"
                readonly
                :disabled="true"
              />
            </div>
          </div>
          <div class="flex flex-row">
            <div class="w-1/2">
              <span class="text-lg font-bold">Total DO Volume :</span>
            </div>
            <div class="w-1/2">
              <vs-input
                v-model="TotalDO.Volume"
                class="w-full"
                readonly
                :disabled="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  props: {
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // this.getVehicle();
  },
  data() {
    //set date tomorrow
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return {
      table: this.tableDefaultState(),
      //set deliveryDate to timenow
      deliveryDate: moment(tomorrow).format("YYYY-MM-DD"),
      itemVehicleAdd: [],
      selectedVehicle: null,
      optionalVehicle: [],
      optionVehicleAdd: [],
      ownership: "",
      selectedDriver: null,
      optionalDriver: [],
      selectedTypeDriver: null,
      optionalTypeDriver: [
        {
          id: 1,
          name: "Internal",
        },
        {
          id: 2,
          name: "External",
        },
        {
          id: 3,
          name: "OneTime",
        },
      ],
      selectedOwnership: null,
      optionalOwnership: [
        {
          id: 1,
          name: "Internal",
        },
        {
          id: 2,
          name: "External",
        },
      ],
      isShowAddVehicle: false,
      TransportDeliveryPlanLineIDs: [],
      additional_vehicle: [],
      driverOnetimeNote: "",
      isOnetime: false,
      TotalDO: {
        Weight: 0,
        Volume: 0,
      },
      TotalVehicle: {
        Weight: 0,
        Volume: 0,
      },
      dataViews: this.$store.state.tms.tms.selected,
    };
  },
  watch: {
    //set value ownership from selectedVehicle
    // selectedVehicle: {
    //   handler: function (val) {
    //     if (val) {
    //       this.ownership = val.ownership;
    //     } else {
    //       this.ownership = "";
    //     }
    //   },
    //   deep: true,
    // },
    selectedTypeDriver: {
      handler: function (val) {
        if (val) {
          if (val.name == "OneTime") {
            this.isOnetime = true;
          } else {
            this.isOnetime = false;
          }
        } else {
          this.isOnetime = false;
        }
      },
      deep: true,
    },
    selectedVehicle: {
      handler: function (val) {
        if (val) {
          //set total weight and volume
          this.TotalVehicle.Weight = val.capacity_weight;
          this.TotalVehicle.Volume = val.capacity_volume;
          this.TotalDO.Weight = this.dataViews.reduce(
            (acc, cur) => acc + cur.weight,
            0
          );
          this.TotalDO.Volume = this.dataViews.reduce(
            (acc, cur) => acc + cur.volume,
            0
          );
        }
      },
      deep: true,
    },
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        searchVehicle: "",
        searchDriver: "",
        searchDriverAdd: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    showAddVehicle() {
      this.isShowAddVehicle = true;
      this.itemVehicleAdd.push({
        Svehicle: {},
        optionVehicle: [],
        ownership: "",
        isOnetimes: false,
        driverOnetimeNote: "",
        selectedTypeDriver: {},
        selectedDriver: null,
        optionalDriver: [],
        selectedOwnership: null,
        optionalOwnership: [
          {
            id: 1,
            name: "Internal",
          },
          {
            id: 2,
            name: "External",
          },
        ],
      });
      this.getVehicleAdd(this.itemVehicleAdd.length - 1);
    },
    getValueOwnershipVehicleAdd(index) {
      this.searchVehicle = "";
      this.tableDefaultState.search = "";
      //insert into itemVehicleAdd[index].ownership
      if (this.itemVehicleAdd[index].Svehicle.ownership == "External") {
        this.itemVehicleAdd[index].ownership =
          this.itemVehicleAdd[index].Svehicle.supplier_name;
      } else {
        this.itemVehicleAdd[index].ownership = "Internal";
      }
      if (this.itemVehicleAdd[index].Svehicle.driver_id != null) {
        this.itemVehicleAdd[index].selectedDriver = {
          id: this.itemVehicleAdd[index].Svehicle.driver_id,
          name: this.itemVehicleAdd[index].Svehicle.driver_name,
        };
        if (this.itemVehicleAdd[index].Svehicle.type == "Internal") {
          this.itemVehicleAdd[index].selectedTypeDriver = {
            id: 1,
            name: "Internal",
          };
          this.getDriverAdd(index);
        } else if (this.itemVehicleAdd[index].Svehicle.type == "External") {
          this.itemVehicleAdd[index].selectedTypeDriver = {
            id: 2,
            name: "External",
          };
          this.getDriverAdd(index);
        } else if (this.itemVehicleAdd[index].Svehicle.type == "OneTime") {
          this.itemVehicleAdd[index].selectedTypeDriver = {
            id: 3,
            name: "OneTime",
          };
        } else {
          this.itemVehicleAdd[index].selectedTypeDriver = null;
        }
      }
    },
    getVehicle() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/vehicles", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.searchVehicle,
            order: this.table.order,
            sort: this.table.sort,
            date: this.deliveryDate,
            ownership: this.selectedOwnership
              ? this.selectedOwnership.name
              : "",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalVehicle = resp.data.records;
              if (this.optionalVehicle.length > 0) {
                // this.selectedVehicle = null;
              } else {
                this.optionalVehicle = [];
                this.selectedVehicle = {};
              }
            } else {
              this.optionalVehicle = [];
              this.selectedVehicle = {};
            }
            this.table.searchVehicle = "";
            this.table.search = "";
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleSearchVehicle(query) {
      if (query != "") {
        this.table.searchVehicle = query;

        this.getVehicle();
      }
    },
    getVehicleAdd(index) {
      console.log("index", this.itemVehicleAdd[index]);
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/vehicles", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.searchVehicle,
            order: this.table.order,
            sort: this.table.sort,
            date: this.deliveryDate,
            // ownership: this.itemVehicleAdd[index].selectedOwnership
            //   ? this.itemVehicleAdd[index].selectedOwnership.name
            //   : "",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionVehicleAdd = resp.data.records;
              if (this.optionVehicleAdd.length > 0) {
                // this.itemVehicleAdd[index].Svehicle = null;
              } else {
                this.optionVehicleAdd = [];
                this.itemVehicleAdd[index].Svehicle = {};
              }
            } else {
              this.optionVehicleAdd = [];
              this.itemVehicleAdd[index].Svehicle = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleSearchVehicleAdd(query, index) {
      if (query != "") {
        this.table.searchVehicle = query;

        console.log("rrrr", this.itemVehicleAdd);

        this.getVehicleAdd(index);
      }
    },
    getValueOwnership() {
      this.table.searchVehicle = "";
      this.tableDefaultState.search = "";
      if (this.selectedVehicle.ownership == "External") {
        this.ownership = this.selectedVehicle.supplier_name;
      } else {
        this.ownership = "Internal";
      }
      //check if vehicle was have driver then set default driver
      if (this.selectedVehicle.driver_id != null) {
        this.selectedDriver = {
          id: this.selectedVehicle.driver_id,
          name: this.selectedVehicle.driver_name,
        };
        if (this.selectedVehicle.type == "Internal") {
          this.selectedTypeDriver = {
            id: 1,
            name: "Internal",
          };
          this.getDriver();
        } else if (this.selectedVehicle.type == "External") {
          this.selectedTypeDriver = {
            id: 2,
            name: "External",
          };
          this.getDriver();
        } else if (this.selectedVehicle.type == "OneTime") {
          this.selectedTypeDriver = {
            id: 3,
            name: "OneTime",
          };
        } else {
          this.selectedTypeDriver = null;
        }
      }
    },

    getDriverAdd(index) {
      if (this.itemVehicleAdd[index].selectedTypeDriver.name == "OneTime") {
        this.itemVehicleAdd[index].isOnetimes = true;
      } else {
        this.itemVehicleAdd[index].isOnetimes = false;
        this.itemVehicleAdd[index].driverOnetimeNote = "";
      }
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/drivers", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.searchDriverAdd,
            order: this.table.order,
            sort: this.table.sort,
            type: this.itemVehicleAdd[index].selectedTypeDriver.name,
            date: this.deliveryDate,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.itemVehicleAdd[index].optionalDriver = resp.data.records;

              if (this.itemVehicleAdd[index].optionalDriver.length > 0) {
                // this.itemVehicleAdd[index].selectedDriver = null;
              } else {
                this.itemVehicleAdd[index].optionalDriver = [];
                this.itemVehicleAdd[index].selectedDriver = {};
              }
            } else {
              this.itemVehicleAdd[index].optionalDriver = [];
              this.itemVehicleAdd[index].selectedDriver = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDriver() {
      if (this.selectedTypeDriver == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please fill the type driver",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/drivers", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.searchDriver,
            order: this.table.order,
            sort: this.table.sort,
            type: this.selectedTypeDriver.name,
            date: this.deliveryDate,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalDriver = resp.data.records;

              if (this.optionalDriver.length > 0) {
                // this.selectedDriver = null;
              } else {
                this.optionalDriver = [];
                // this.selectedDriver = {};
              }
            } else {
              this.optionalDriver = [];
              this.selectedDriver = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataDriver() {
      this.table.searchDriver = "";
    },
    handleSearchDriver(query) {
      if (query != "") {
        this.table.searchDriver = query;

        this.getDriver();
      }
    },
    handleSearchDriverAdd(query, index) {
      if (query != "") {
        this.table.searchDriverAdd = query;

        this.getDriverAdd(index);
      }
    },
    addRowVehicleSelected() {
      this.itemVehicleAdd.push({
        Svehicle: {},
        ownership: "",
        isOnetimes: false,
        driverOnetimeNote: "",
        selectedTypeDriver: {},
        selectedDriver: {},
        optionalDriver: [],
      });
      this.getVehicleAdd(this.itemVehicleAdd.length - 1);
    },
    removeRowVehicleSelected(index) {
      this.itemVehicleAdd.splice(index, 1);
      if (this.itemVehicleAdd.length == 0) {
        this.isShowAddVehicle = false;
      }
    },
    Save() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let st = 1;
          this.itemVehicleAdd.forEach((element) => {
            if (
              element.Svehicle.id == null ||
              element.selectedDriver.id == null
            ) {
              this.$vs.notify({
                title: "Error",
                text: "Please fill the vehicle and driver",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
              st = 0;
              return;
            }
          });
          if (st == 0) {
            return;
          }
          //check duplicate vehicle and driver in itemVehicleAdd also in selectedVehicle and selectedDriver
          let isDuplicateVehicle = false;
          let isDuplicateDriver = false;
          let isDuplicateDriverAdd = false;
          let isDuplicateVehicleAdd = false;

          //check duplicate vehicle and driver in itemVehicleAdd
          this.itemVehicleAdd.forEach((element, index) => {
            if (element.Svehicle.id != null) {
              this.itemVehicleAdd.forEach((element2, index2) => {
                if (
                  element.Svehicle.id == element2.Svehicle.id &&
                  index != index2
                ) {
                  isDuplicateVehicleAdd = true;
                }
              });
            }

            if (element.selectedDriver.id != null) {
              this.itemVehicleAdd.forEach((element2, index2) => {
                if (
                  element.selectedDriver.id == element2.selectedDriver.id &&
                  index != index2
                ) {
                  isDuplicateDriverAdd = true;
                }
              });
            }
          });

          //check duplicate vehicle and driver in selectedVehicle and selectedDriver
          if (this.selectedVehicle.id != null) {
            this.itemVehicleAdd.forEach((element) => {
              if (element.Svehicle.id == this.selectedVehicle.id) {
                isDuplicateVehicle = true;
              }
            });
          }

          if (this.selectedDriver.id != null) {
            this.itemVehicleAdd.forEach((element) => {
              if (element.selectedDriver.id == this.selectedDriver.id) {
                isDuplicateDriver = true;
              }
            });
          }

          if (this.selectedVehicle.id == null) {
            this.$vs.notify({
              title: "Error",
              text: "Please fill the vehicle",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            return;
          }

          if (isDuplicateVehicleAdd) {
            this.$vs.notify({
              title: "Error",
              text: "Duplicate Vehicle in Additional Vehicle",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            return;
          }

          if (isDuplicateDriverAdd) {
            this.$vs.notify({
              title: "Error",
              text: "Duplicate Driver in Additional Vehicle",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            return;
          }

          if (isDuplicateVehicle) {
            this.$vs.notify({
              title: "Error",
              text: "Duplicate Vehicle in Additional Vehicle and Main Vehicle",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            return;
          }

          if (isDuplicateDriver) {
            this.$vs.notify({
              title: "Error",
              text: "Duplicate Driver in Additional Vehicle and Main Vehicle",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            return;
          }
          if (this.isOnetime && this.driverOnetimeNote == "") {
            this.$vs.notify({
              title: "Error",
              text: "Please fill the note",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            return;
          }
          this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: `Confirm`,
            text: "Are you sure to Process this data?",
            accept: () => {
              this.checkLockDriverVehicle();

              // this.SaveData();
            },
          });
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Failed",
            text: "Please complete the form",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
      });
    },

    SaveData() {
      this.itemVehicleAdd.forEach((element) => {
        if (element.isOnetimes && element.driverOnetimeNote == "") {
          this.$vs.notify({
            title: "Error",
            text: "Please fill the note",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return;
        }

        if (element.Svehicle.id != null) {
          // console.log("IIIII", element.Svehicle.id);
          //combine selectedDriver.name with driverOnetimeNote
          var vehicle_combinasi;
          vehicle_combinasi =
            element.selectedDriver.name + "-" + element.driverOnetimeNote;

          this.additional_vehicle.push({
            driver_availability_id: element.Svehicle.id,
            vehicle_id: element.Svehicle.vehicle_id,
            vehicle_number: element.Svehicle.number,
            driver_id: element.selectedDriver.id,
            driver_name: element.isOnetimes
              ? vehicle_combinasi
              : element.selectedDriver.name,
            ownership: element.ownership,
            type_driver: element.selectedTypeDriver.name,
          });
        }
      });
      this.TransportDeliveryPlanLineIDs = [];
      this.selectedDO = this.$store.state.tms.tms.selected;
      this.dataViews.forEach((element) => {
        this.TransportDeliveryPlanLineIDs.push(element.id);
      });
      if (this.TransportDeliveryPlanLineIDs.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select the delivery order",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }
      let totalVolume = 0;
      let totalWeight = 0;
      this.dataViews.forEach((element) => {
        totalVolume += element.volume;
        totalWeight += element.weight;
      });

      let DriverAvailabilityUsed = [];
      this.itemVehicleAdd.forEach((element) => {
        if (element.Svehicle.id != null) {
          DriverAvailabilityUsed.push({
            driver_availability_id: element.Svehicle.id,
            driver_id: element.selectedDriver.id,
          });
        }
      });

      if (this.selectedVehicle.id != null) {
        DriverAvailabilityUsed.push({
          driver_availability_id: this.selectedVehicle.id,
          driver_id: this.selectedDriver.id,
        });
      }
      // console.log("DriverAvailabilityUsed", DriverAvailabilityUsed);
      var vehicle_combinasi_v2;
      vehicle_combinasi_v2 =
        this.selectedDriver.name + "-" + this.driverOnetimeNote;

      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/transport-code/releaseV2", {
          transport_delivery_plan_line_id: this.TransportDeliveryPlanLineIDs,
          delivery_date: this.deliveryDate,
          driver_availability_id: this.selectedVehicle.id,
          vehicle_id: this.selectedVehicle.vehicle_id,
          vehicle_number: this.selectedVehicle.number,
          driver_id: this.selectedDriver.id,
          ownership: this.selectedOwnership.name,
          type_driver: this.selectedTypeDriver.name,
          total_volume: totalVolume,
          total_weight: totalWeight,
          driver_name: this.isOnetime
            ? vehicle_combinasi_v2
            : this.selectedDriver.name,
          add_vehicle: this.additional_vehicle,
          driver_availability_used: DriverAvailabilityUsed,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Shipment has been released",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.$emit("close");
            this.$store.commit("tms/tms/setSelected", []);
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
    minDate() {
      // Create a new Date object
      const date = new Date();

      // Get the time in GMT+7
      const gmt7Time = new Date(date.getTime() + 7 * 60 * 60 * 1000);

      // Extract the year, month, and date
      const year = gmt7Time.getUTCFullYear();
      const month = String(gmt7Time.getUTCMonth() + 1).padStart(2, "0");
      const day = String(gmt7Time.getUTCDate()).padStart(2, "0");

      // Extract the hours and minutes
      const hours = String(gmt7Time.getUTCHours()).padStart(2, "0");
      const minutes = String(gmt7Time.getUTCMinutes()).padStart(2, "0");

      // Format the date and time to a string suitable for the input type
      return `${year}-${month}-${day}`;
    },
    checkLockDriverVehicle() {
      // console.log("selectedVehicle", this.selectedVehicle);
      var DAID = [];
      this.selectedVehicle.vehicle_id != null
        ? DAID.push(this.selectedVehicle.vehicle_id)
        : "";
      if (this.itemVehicleAdd.length > 0) {
        this.itemVehicleAdd.forEach((element) => {
          if (element.Svehicle.vehicle_id != null) {
            DAID.push(element.Svehicle.vehicle_id);
          }
        });
      }
      var DRID = [];
      this.selectedDriver.id != null ? DRID.push(this.selectedDriver.id) : "";
      if (this.itemVehicleAdd.length > 0) {
        this.itemVehicleAdd.forEach((element) => {
          if (element.selectedDriver.id != null) {
            DRID.push(element.selectedDriver.id);
          }
        });
      }
      // console.log("DRID", DRID);
      // console.log("DAID", DAID);
      // console.log("itemVehicleAdd", this.itemVehicleAdd);

      if (this.selectedVehicle.id != null) {
        this.$http
          .get("/api/wms/v1/transport-code/check-lock", {
            params: {
              driver_id: DRID,
              vehicle_id: DAID,
            },
          })
          .then((resp) => {
            if (resp.code == 400) {
              this.$vs.dialog({
                type: "confirm",
                color: "warning",
                title: `Confirm`,
                text: resp.message,
                accept: () => {
                  this.SaveData();
                },
              });
            } else {
              this.SaveData();
            }
          });
      }
    },
  },
};
</script>
<style>
.vl {
  border-left: 6px solid black;
  height: 100%;
}
</style>
